<template>
  <div v-if="showData">
    <!-- 預約時間 -->
    <b-tabs class="mt-2 mx-2 mx-sm-5">
      <b-tab title="預約時間＆日期">
        <b-card-text>

          <b-col
            v-for="(step, stepIndex) in stepOrder"
            :key="stepIndex"
            cols="12"
            md="12"
          >
            <label class="h5 mt-2">
              STEP{{ stepIndex + 1 }} {{ step.label }}
            </label>

            <!-- 預約項目 -->
            <template v-if="step.type === 'item'">
              <b-input-group>
                <b-form-input
                  :value="reserveServices.map(m => `${m.name} - (${m.interval}min)`).join(',')"
                  disabled
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="$router.push({ name: 'apps-reserve-v2-page', params: { slug: $route.params.slug, code: $route.params.code } })"
                  >
                    重新選
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </template>

            <!-- 預約日期 -->
            <template v-else-if="step.type === 'date'">
              <b-form-datepicker
                v-model="reserveOrder.date"
                v-bind="zh_TW_date"
                :min="new Date()"
                :date-disabled-fn="dateDisabled"
                @input="selectDate"
              />
            </template>

            <!-- 預約時間 -->
            <template v-else-if="step.type === 'time'">
              <div
                v-if="!reserveOrder.time"
                class="d-flex justify-content-start flex-wrap"
              >
                <b-form-input
                  v-if="periodData.length === 0"
                  disabled
                />
                <b-button
                  v-for="(period, index) in periodData"
                  :key="index"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="p-0 rounded-circle my-50 mx-25 width-50 height-50"
                  :disabled="period.status"
                  :variant="period.status ? `gradient-secondary` : `gradient-dark`"
                  @click="period.status ? null : selectTime(period.value)"
                >
                  {{ period.value }}
                </b-button>
              </div>

              <b-input-group v-if="reserveOrder.time">
                <b-form-timepicker
                  v-model="reserveOrder.time"
                  v-bind="zh_TW_time"
                  disabled
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="$delete(reserveOrder, 'time')"
                  >
                    重新選
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </template>

            <!-- 填寫資料 -->
            <template v-else-if="step.type === 'write-info'">

              <!-- 姓名 -->
              <b-form-group
                label="姓名(*)"
                label-for="name"
                label-cols="3"
                label-cols-lg="1"
                class="mt-1"
              >
                <b-form-input
                  id="name"
                  v-model="reserveOrder.name"
                  placeholder="姓名"
                />
              </b-form-group>

              <!-- 手機 -->
              <b-form-group
                label="手機(*)"
                label-for="phone"
                label-cols="3"
                label-cols-lg="1"
              >
                <b-form-input
                  id="phone"
                  v-model="reserveOrder.phone"
                  placeholder="手機"
                />
              </b-form-group>

              <!-- 信箱 -->
              <b-form-group
                label="信箱(*)"
                label-for="email"
                label-cols="3"
                label-cols-lg="1"
              >
                <b-form-input
                  id="email"
                  v-model="reserveOrder.email"
                  placeholder="信箱"
                />
              </b-form-group>

              <!-- 備註 -->
              <b-form-group
                label="備註"
                label-for="remark"
                label-cols="3"
                label-cols-lg="1"
              >
                <b-form-textarea
                  id="remark"
                  v-model="reserveOrder.remark"
                  rows="4"
                />
              </b-form-group>

            </template>

            <hr class="mt-2 mb-1">
          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <b-button
              class="mb-2"
              variant="dark"
              :disabled="disabledSubmit"
              block
              @click="sendReserve"
            >
              確定
            </b-button>
          </b-col>

        </b-card-text>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import {
  BTabs, BTab, BCardText, BCol, BButton,
  BFormGroup, BFormInput, BFormTextarea,
  BFormDatepicker, BFormTimepicker,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

//
import dataStoreModule from './reserveStoreModule'

export default {
  components: {
    BTabs,
    BTab,
    BCardText,
    BCol,
    BButton,

    BFormGroup,
    BFormInput,
    BFormTextarea,

    BFormDatepicker,
    BFormTimepicker,

    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  setup(_, { root }) {
    const APP_STORE_MODULE_NAME = 'app-client-reserve'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, dataStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // Data func
    const reserveServices = computed(() => store.state.saleOrder.reserve_services)
    if (reserveServices.value.length === 0) {
      root.$router.push({ name: 'apps-reserve-v2-page', params: { slug: root.$route.params.slug, code: root.$route.params.code } })
    }

    const showData = ref({})

    const reserveOrder = ref({})

    const stepOrder = [
      { type: 'item', label: '確認預約項目' },
      { type: 'date', label: '選擇日期' },
      { type: 'time', label: '選擇時間' },
      { type: 'write-info', label: '填寫資料' },
    ]

    // 讀取當前上班日
    const workDayData = ref([])
    store.dispatch(`${APP_STORE_MODULE_NAME}/fetchPersonnelDate`, {
      slug: root.$route.params.slug,
      code: root.$route.params.code,
    })
      .then(response => {
        workDayData.value = response.data
      })

    // 日期禁用的條件
    const dateDisabled = (ymd, date) => {
      const isDuringDate = (beginDateStr, endDateStr, curDateStr) => {
        const curDate = new Date(curDateStr)
        const beginDate = new Date(beginDateStr)
        const endDate = new Date(endDateStr)
        return curDate >= beginDate && curDate <= endDate
      }
      return workDayData.value.some(value => isDuringDate(value.start_date, value.end_date, date.format('yyyy-MM-dd')))
    }

    // 選擇日期
    const periodData = ref([])
    const selectDate = date => {
      root.$delete(reserveOrder.value, 'time')
      store.dispatch(`${APP_STORE_MODULE_NAME}/fetchPersonnelTime`, {
        slug: root.$route.params.slug,
        code: root.$route.params.code,
        reserve_item_ids: reserveServices.value.map(m => m.id),
        date,
      })
        .then(response => {
          periodData.value = response.data
        })
    }

    // 選擇時間
    const selectTime = time => {
      root.$set(reserveOrder.value, 'time', time)
    }

    // 發送預約訂單
    const sendReserve = () => {
      const queryParams = {
        slug: root.$route.params.slug,
        code: root.$route.params.code,
        reserve_item_ids: reserveServices.value.map(m => m.id),
        date: reserveOrder.value.date,
        time: reserveOrder.value.time,
        name: reserveOrder.value.name,
        phone: reserveOrder.value.phone,
        email: reserveOrder.value.email,
        line_uuid: store.state.saleOrder.line_uuid,
      }
      // console.log(store.state.saleOrder)
      store.dispatch(`${APP_STORE_MODULE_NAME}/addReserveOrder`, queryParams)
        .then(response => {
          const { serial_no } = response.data
          root.$router.push({ name: 'apps-reserve-v2-received', params: { serial_no } })
          // root.$swal.fire({
          //   title: '完成訂單',
          //   html: '<span>完成訂單</span>',
          //   icon: 'success',
          // })
        })
        .catch(error => {
          const { status } = error.response
          const { message } = error.response.data
          if (status === 400) {
            root.$swal.fire({
              title: '無法建立',
              html: `<span>${message}</span>`,
              icon: 'error',
            })
          } else {
            root.$swal.fire({
              title: '無法建立',
              html: '<span>無法建立</span>',
              icon: 'error',
            })
          }
        })
    }

    const disabledSubmit = computed(() => {
      const { date, time } = reserveOrder.value
      return !date || !time
    })

    return {
      // ref
      stepOrder,
      showData,
      reserveOrder,
      periodData,

      // func
      dateDisabled,
      selectDate,
      selectTime,
      sendReserve,

      // computed
      reserveServices,
      disabledSubmit,
    }
  },
  data() {
    return {
      zh_TW_date: {
        labelPrevYear: '上一年',
        labelPrevMonth: '上個月',
        labelCurrentMonth: '當前月份',
        labelNextMonth: '下個月',
        labelNextYear: '明年',
        labelToday: '今天',
        labelSelected: '選定日期',
        labelNoDateSelected: '未選擇日期',
        labelCalendar: '日曆',
        labelNav: '日曆導航',
        labelHelp: '使用游標鍵點選日期',
      },
      zh_TW_time: {
        labelHours: '小時',
        labelMinutes: '分鐘',
        labelSeconds: '秒',
        labelAmpm: '上午下午',
        labelAm: '上午',
        labelPm: '下午',
        labelIncrement: '增量',
        labelDecrement: '減量',
        labelSelected: '選定時間',
        labelNoTimeSelected: '沒有選擇時間',
        labelCloseButton: '關',
      },
    }
  },
}
</script>

<style lang="scss" >
.card-img-top {
  height: 200px;
  width: 100%;
  object-fit: cover;
  object-position: 100% 10%;
  opacity: 0.6;
}
</style>
